// $sm: 640px;
// $md: 768px;
// $lg: 1024px;
// $xl: 1200px;

// $type: screen;
// $proto: min-width;
// // 无人车模块背景图
// $UACBgSm: url('https://p0.meituan.net/smartvenus/229b70d1e2f2583db0f4757296cae05533757.png');
// $UACBgLg: url('https://p0.meituan.net/smartvenus/ef0d943144626b46e256fdf99927cff0233447.png');

// // 自动车模块背景图
// $autocarBgSm: url('https://p0.meituan.net/smartvenus/aa24f1228b14892db7e05be1517affa461089.png');
// $autocarBgLg: url('https://p0.meituan.net/smartvenus/fa22a2237381c588f539ec23f9345c0c252185.png');
.technology-conetnt-box {
  // .UAVbox {
  //   background-image: $UACBgSm;
  // }
  // .autocarbox {
  //   background-image: $autocarBgSm;
  // }
  // @media #{$type} and ($proto: $lg) {
  //   .UAVbox {
  //     background-image: $UACBgLg;
  //   }
  //   .autocarbox {
  //     background-image: $autocarBgLg;
  //   }
  // }

  .technology-conetnt-uav-car {
    @apply flex flex-col  bg-[#f7f8f9] px-0 pb-5 pt-5 lg:px-5;
    @apply lg:space-y-[60px] lg:py-[60px];
  }

  .content-item-box {
    @apply w-full justify-center overflow-hidden bg-cover bg-bottom bg-no-repeat p-5;
    @apply lg:p-0;
  }
  .technoloy-content-box {
    @apply relative rounded-lg bg-white p-5 lg:rounded-2xl lg:p-15;
    .content-item-title {
      @apply mt-0 border-0;
    }
  }
  .technoloy-uav-content-box {
    @apply flex flex-col items-center justify-center pb-0;
    @apply lg:block lg:pb-15;
  }
  // 无人机
  .uav-img-box {
    @apply relative w-[100vw];
    @apply lg:absolute lg:-right-[8%] lg:top-[87px] lg:h-auto lg:w-[66.25%];
  }

  .content-item-wrap {
    @apply w-full lg:m-auto lg:max-w-screen-xl;
    // 标题部分
    .content-item-title {
      @apply mb-[10px] flex max-w-full flex-col font-medium text-black-90;
      @apply mt-0 text-xl leading-7 lg:mt-[50px];
      @apply lg:mt-0 lg:inline-block lg:text-4.5xl lg:leading-3.5r;
      // color: rgba(0, 0, 0, 0.9);
    }
    .content-item-title-item {
      @apply lg:inline;
    }
    .content-item-title-limit {
      @apply lg:max-w-3xl;
    }

    // 介绍内容部分
    .content-item-intro {
      color: rgba(0, 0, 0, 0.6);
      @apply mb-5 w-full text-xs leading-5;
      @apply lg:mb-[40px] lg:text-base lg:leading-6;
    }
    .content-item-intro-limit {
      @apply lg:w-[520px];
    }

    .content-item-intro-sm {
      @apply mb-5 mt-[4px] lg:mb-[30px];
      font-size: 14px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.6);
    }
    .content-item-data-box {
      @apply flex items-baseline leading-none;
    }
    .item-data-left {
      @apply font-medium text-black-90;
      margin-right: 4px;
      font-size: 20px;
      line-height: 28px;
    }
    .item-data-mid {
      @apply text-meituan;
      @apply text-2sm;
      @apply lg:text-4sm;
      font-weight: bold;
      .mid-order-quantity {
        vertical-align: bottom;
      }
      .mid-order-unit {
        @apply text-[26px];
        @apply leading-[28px];
        @apply lg:text-[51px];
        @apply lg:leading-[56px];
      }
    }
    .item-data-right {
      @apply font-medium text-black-90;
      margin-left: 4px;
      font-size: 20px;
      line-height: 28px;
    }
    // 分割线
    .item-divide-line {
      @apply mx-[0.5rem] h-5 border-l border-black-45 opacity-[0.35] lg:mr-3 lg:h-10;
    }
  }
  // 自动车
  .auto-car-box {
    // @apply relative flex flex-col lg:flex-row;
    @apply flex flex-col items-center justify-center pb-5;
    @apply lg:block lg:pb-15;
    .auto-car-content {
      @apply lg:w-32.25r;
    }
    .auto-car-img-box {
      @apply relative w-[110vw] w-full;
      @apply lg:absolute lg:-right-[5%] lg:left-auto lg:top-[60px] lg:h-auto lg:w-[54%] lg:py-0 xl:w-[65%];
      // position: absolute;
      // width: 67%;
      // height: auto;
      // right: -5%;
      // top: 60px;
    }
  }

  // 相关链接
  .item-link-box {
    @apply flex items-center;
    @apply mb-6;
    @apply lg:mb-12;
    .item-link {
      @apply mr-0.4r text-sm font-medium;
      @apply lg:text-xl;
    }
    .item-arrow {
      @apply h-2 w-2 rotate-45 border-b-0 border-l-0 border-r-1 border-t-1;
      @apply lg:h-2.5 lg:w-2.5;
    }
  }

  // 视频相关
  .video-box {
    @apply flex flex-col space-y-3;
    @apply md:flex-row md:space-x-5 md:space-y-0;
    .video-wrap {
      @apply overflow-hidden rounded-lg;
      @apply md:h-auto md:w-1/2 md:rounded-2xl;
      // @apply lg:h-22.13r lg:w-1/2 lg:rounded-2xl;
      :global(.video-js .vjs-tech) {
        pointer-events: none;
      }
    }
  }

  .comma-box {
    @apply hidden;
    @apply lg:inline-block;
  }
}
