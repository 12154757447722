.footer-news-box {
  @apply m-auto w-full max-w-7xl;
  @apply px-5 pb-[30px] pt-[30px];
  @apply lg:px-0 lg:py-10;
  .footer-news-wrap {
    @apply mx-auto;
  }
  .footer-title-link-box {
    @apply pb-5;
  }
  .news-item-box {
    @apply flex flex-col space-y-3;
    @apply sm:flex-row sm:space-x-2 sm:space-y-0;
    @apply lg:flex-row lg:space-x-5 lg:space-y-0;
  }
  // .news-item-box:last-child {
  //   @apply sm:hidden;
  // }
  .news-item-box-wrap {
    @apply min-w-0 flex-1 sm:last:hidden lg:last:block;
  }
}
