.videoplayerbox {
  :global(.video-js .vjs-tech) {
    @apply object-cover;
  }
  .playercontainer {
    :global(.video-js .dxp-skin-layout .vjs-poster) {
      background-color: transparent;
      background-size: cover;
      cursor: default;
    }
    :global(.video-js .vjs-control-bar) {
      @apply text-mini;
      @apply lg:pl-3 lg:text-xs;
    }
    :global(.video-js .vjs-control-bar .vjs-control) {
      @apply leading-1.125r;
      @apply leading-5;
    }
    :global(.dxp-skin-layout:not(.vjs-fullscreen).vjs-layout-small .vjs-current-time) {
      @apply flex;
    }
    :global(.dxp-skin-layout:not(.vjs-fullscreen).vjs-layout-x-small .vjs-current-time) {
      @apply flex;
    }
    :global(.vjs-control:hover) {
      :global(.vjs-slider-bar:before) {
        top: -0.4em;
        font-size: 0.8em;
      }
    }

    // 中间三角形
    :global(.video-js .vjs-big-play-button .vjs-icon-placeholder:before) {
      @apply h-[24px] w-[24px] bg-center bg-no-repeat;
      content: ' ';
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiLz48cGF0aCBkPSJNMjIuNzMyIDEzLjg3MUw3LjcwNiAyMi42MzZhMS42NjcgMS42NjcgMCAwIDEtMi41MDYtMS40NFYzLjY2N2ExLjY2NyAxLjY2NyAwIDAgMSAyLjUwNi0xLjQzOWwxNS4wMjYgOC43NjVhMS42NjcgMS42NjcgMCAwIDEgMCAyLjg4eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9nPjwvc3ZnPg==');
      // background-image: url('https://p0.meituan.net/smartvenus/f6c11f90d8baf09c8c01fdee5b5b273d503.png');
      background-size: 100%;
    }

    :global(.video-js .vjs-playing.vjs-user-active .vjs-big-play-button .vjs-icon-placeholder:before) {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiLz48cmVjdCBmaWxsPSIjRkZGIiB4PSI0IiB5PSIyIiB3aWR0aD0iNiIgaGVpZ2h0PSIyMCIgcng9IjIiLz48cmVjdCBmaWxsPSIjRkZGIiB4PSIxNCIgeT0iMiIgd2lkdGg9IjYiIGhlaWdodD0iMjAiIHJ4PSIyIi8+PC9nPjwvc3ZnPg==');
    }

    :global(.video-js .vjs-play-control .vjs-icon-placeholder:before) {
      @apply absolute h-[24px] w-[24px] bg-center bg-no-repeat;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: ' ';
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiLz48cGF0aCBkPSJNMjIuNzMyIDEzLjg3MUw3LjcwNiAyMi42MzZhMS42NjcgMS42NjcgMCAwIDEtMi41MDYtMS40NFYzLjY2N2ExLjY2NyAxLjY2NyAwIDAgMSAyLjUwNi0xLjQzOWwxNS4wMjYgOC43NjVhMS42NjcgMS42NjcgMCAwIDEgMCAyLjg4eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9nPjwvc3ZnPg==');
      // background-image: url('https://p0.meituan.net/smartvenus/f6c11f90d8baf09c8c01fdee5b5b273d503.png');
      background-size: 24px 24px;
    }
    :global(.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before) {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiLz48cmVjdCBmaWxsPSIjRkZGIiB4PSI0IiB5PSIyIiB3aWR0aD0iNiIgaGVpZ2h0PSIyMCIgcng9IjIiLz48cmVjdCBmaWxsPSIjRkZGIiB4PSIxNCIgeT0iMiIgd2lkdGg9IjYiIGhlaWdodD0iMjAiIHJ4PSIyIi8+PC9nPjwvc3ZnPg==');
    }

    .player-waiting-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9999;
      background: transparent;
      color: #fff;
      cursor: pointer;
    }
    :global(.video-js .vjs-big-play-button) {
      @apply h-15 w-15 text-4xl;
      background: rgba(0, 0, 0, 0.35);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      border: none;
      transform: translate(-50%, -50%);
    }

    :global(.video-js .video-layout-hide-control .vjs-big-play-button) {
      display: none !important;
    }

    .video-js.vjs__nice_sixteen {
      padding-bottom: 56.25% !important;
    }
  }
  .playerBox {
    position: absolute;
    inset: 0;
  }
}
@media (max-width: 768px) {
  // 在移动端，无论竖屏还是横屏视频，皆100%宽度
  .videoplayerbox {
    width: 100% !important;
  }
}
