.video-banner-box {
  @apply relative mt-15 w-full;
  @apply lg:mt-0;
  .coverimg-box {
    @apply h-[260px] w-full;
    @apply lg:h-[400px];
  }
  .video-content {
    @apply absolute left-0 top-0 flex h-full w-full flex-col items-center;

    .video-title {
      @apply mt-[70px] text-center text-white;
      @apply lg:mt-[180px];
      span {
        @apply mt-[4px] block text-[26px] leading-[36px];
        @apply lg:inline lg:text-[52px] lg:leading-[72px];
        &:first-child {
          @apply mt-0;
        }
      }

      &.video-title-no {
        @apply mt-[112px];
        @apply lg:mt-[180px];
      }
    }
    .video-play-box {
      @apply flex cursor-pointer items-center  justify-center  bg-[rgba(0,0,0,0.35)];
      @apply mt-[16px] rounded-[100px] py-[3px] pl-[4px] pr-[10px] backdrop-blur-[50px];
      @apply lg:mt-[12px] lg:rounded-[200px] lg:py-[8px] lg:pl-[8px] lg:pr-[18px] lg:backdrop-blur-[100px];
      .video-play-text {
        @apply text-white;
        @apply lg:text-[14px] lg:leading-[22px];
        @apply lg:text-[18px] lg:leading-[24px];
      }
      .video-play-btn {
        @apply mr-[4px] flex items-center justify-center;
        @apply h-[20px] w-[20px] p-[1px];
        @apply lg:h-[28px] lg:w-[28px] lg:p-[2px];
        @apply bg-cover bg-no-repeat;
        background-image: url('https://p0.meituan.net/smartvenus/3e92a4f8c5ee7946ac9da85f87a917a71002.png');
      }
    }
  }
}
.modal-box {
  @apply relative z-20;
}

.modal-mask {
  @apply fixed inset-0 z-30 bg-black bg-opacity-90 lg:bg-opacity-10;
}
.sm-close-btn {
  @apply block lg:hidden;
}
.lg-close-btn {
  @apply hidden lg:block;
}
.close-btn {
  @apply absolute right-5 top-5 h-5 w-5 cursor-pointer rounded-full;
  @apply lg:border-none;
  z-index: 9999;
}
.close-btn::before,
.close-btn::after {
  @apply absolute left-2/4  top-2/4 h-3 w-px -translate-x-2/4 -translate-y-2/4 bg-white-darkest;
  @apply lg:h-6 lg:w-0.5;
  content: ' ';
}

.close-btn::before {
  @apply rotate-45;
}

.close-btn::after {
  @apply -rotate-45;
}

.video-tag {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}
