@media (min-width: 1024px) {
  .news-item-box:hover {
    @apply transition-all;
    // box-shadow: 0 4px 12px 0 rgb(17 25 37 / 10%);
    .img-box {
      @apply scale-110;
    }
    .img-box::after {
      opacity: 0.15;
    }
  }
}

.news-item-box {
  @apply relative;
  transform: translateZ(0);
  .img-box {
    @apply relative;
    // box-shadow: inset 0 0 21px 0 #e8e8ea;
    transition: all 0.3s ease-in-out;
  }
  .img-box::after {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
  }
}
.img-mask-box {
  @apply absolute bottom-0 left-0 right-0 top-0 border bg-blue-200;
}

// .news-item-center-wrap:not(:last-child)::after {
//   content: '';
//   @apply absolute -bottom-[10px] block h-0 w-full border-t-1;
// }
// .news-item-center-wrap::after {
//   content: '';
//   @apply absolute -bottom-[10px] block h-0 w-full border-t-1 border-black-5;
// }
